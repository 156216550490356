<template>
  <div class="home">
    <div class="columns is-multiline is-mobile">
      <div class="column is-4-tablet is-6-mobile is-3-desktop"
           v-for="item in cardLinks"
           :key="item.name">
        <home-cards-item
            v-if="($alfalab.allowOnlyLapi($auth.user(), item))"
            prefix="admin"
            :data="item"
        ></home-cards-item>
      </div>
    </div>
  </div>
</template>

<script>
import HomeCardsItem from '../../components/dashboard/HomeCardsItem'
export default {
  name: "Index",
  components: {HomeCardsItem},
  computed: {
    cardLinks() {
      return this.$store.state.admin.links || []
    }
  }
}
</script>

<style scoped>

</style>
